.blink {
  animation: blink-animation 2s infinite;
  -webkit-animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink-animation {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
