body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Fixes vimeo iframe script preventing clicking elements */
iframe {
  z-index: 1 !important;
}


body aside.bn-onboard-modal {
  z-index: 9;
}

body section.bn-onboard-custom.bn-onboard-modal-content {
  border-radius: 30px;
  font-family: NB Akademie Std, Source Sans Pro, Arial,sans-serif;
  padding: 30px 30px 0px 30px;
  position: relative;
  max-width: 592px;
  z-index: 9;
}

body section.bn-onboard-custom::after {
  display: inline-flex;
  position: absolute;
  top: 30px;
  right: 30px;
  content: '';
  background-image: url('./Icons/iconClose.svg');
  background-size: 19px 19px;
  height: 19px;
  width: 19px;

}
body section.bn-onboard-custom h3{
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  margin-left: 0;
  color: black
}
body section.bn-onboard-custom p{
  color: black;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -2%;
}

body section.bn-onboard-custom p.bn-onboard-select-wallet-definition,
body section.bn-onboard-custom p.bn-onboard-select-wallet-definition a {
  color: #999999;
  font-size: 12px !important;
  font-weight: 700;
  letter-spacing: -2%;
}

body section.bn-onboard-modal-content section.bn-onboard-modal-selected-wallet p {
  color: #999999;
  font-size: 15px !important;
  font-weight: 700;
  letter-spacing: -2%;
}

body section.bn-onboard-custom header {
  margin-bottom: 30px;
}
body section.bn-onboard-custom header.bn-onboard-modal-content-header div.bn-onboard-modal-content-header-icon {
  display: none;
}

body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets {
  margin: 10px 0;

}

body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets div button,
body section.bn-onboard-custom footer button.bn-onboard-prepare-button,
body section.bn-onboard-custom footer a button.bn-onboard-prepare-button:hover,
body section.bn-onboard-custom div.bn-onboard-prepare-button-container button {
  border-radius: 30px;
  font-family: NB Akademie Std,Source Sans Pro,Arial,sans-serif;
  font-Size: 12px;
  letter-spacing: -0.2px;
  padding: 13px 20px;
  background-color: black;
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}

body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets div button:hover{
  background-color: black;
  border: none;
  text-transform: uppercase;
  color: white;
}
body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets button.bn-onboard-icon-button{
  border-radius: 30px;
}
body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets button.bn-onboard-icon-button:hover{
  box-shadow: unset;
  background: #EEEEEE;
}
body section.bn-onboard-custom div.bn-onboard-icon-display span,
body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets button.bn-onboard-icon-button span{
  color: black !important;
  opacity: 1;
  text-transform: uppercase;
  letter-spacing: -0.2px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 15px;
}

body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets button.bn-onboard-icon-button.bn-onboard-selected-wallet{
  background: #EEEEEE;
}

body section.bn-onboard-custom ul.bn-onboard-modal-select-wallets button.bn-onboard-icon-button span i{
  font-style: initial;
  display: none;
}

body section.bn-onboard-custom div.bn-onboard-modal-content-close{
  color: transparent;
  z-index: 3;
  top: 30px;
  right: 30px;
}

body section.bn-onboard-custom div.bn-onboard-modal-content-close:hover{
  background: unset;
}

body section.bn-onboard-custom div.bn-onboard-modal-content-close svg{
  height: 17px;
  width: 17px;
  fill: transparent;
}

body section.bn-onboard-custom div.bn-branding {
  color: white;
}
body section.bn-onboard-custom div.bn-branding a{
  display: none;
}

body section.bn-onboard-custom div.bn-onboard-custom span{
  color: #999999;
  font-size: 12px;
  letter-spacing: -0.2px;
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (max-width: 521px) {
  body section.bn-onboard-custom div.bn-onboard-select-info-container span + button{
    display: none;
  }
}
/* wallet connect stuff */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
